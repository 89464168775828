import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
// import AuthLayout from "layouts/auth";
// import AdminLayout from "layouts/admin";
// import RtlLayout from "layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { store } from "redux/store/store";
import { Provider } from "react-redux";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { I18nextProvider } from "react-i18next";
import { ToastContainer } from "react-toastify";
import i18next from "i18next";
import translationEN from "translations/translations.en.json";
import translationES from "translations/translations.es.json";
import ErrorBoundary from "screens/components/ErrorBoundary/ErrorBoundary";
import "react-toastify/dist/ReactToastify.css";
import FallbackUI from "screens/components/FallbackUI/FallbackUI";
import AuthVerify from "screens/components/AuthVerify/AuthVerify";
import RefreshSession from "layouts/refresh";
import ForgotPassword from "screens/views/auth/forgotPassword";
import ResetPassword from "screens/views/auth/ResetPassword";
import { Notifications } from "react-push-notification";

// import translationES from "./locales/es/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
const AuthLayout = lazy(() => import("layouts/auth"));
const AdminLayout = lazy(() => import("layouts/admin"));
// const RtlLayout = lazy(() => import("layouts/rtl"));

ReactDOM.render(
  <Provider store={store}>
      <Notifications />
      <ErrorBoundary>
        <ChakraProvider theme={theme}>
          <React.StrictMode>
            <I18nextProvider i18n={i18next}>
              <ThemeEditorProvider>
                <HashRouter>
                  <Suspense fallback={<FallbackUI />}>
                    <ErrorBoundary>
                      <Switch>
                        <Route path={`/auth`} component={AuthLayout} />
                        <Route path={`/admin`} component={AdminLayout} />
                        <Route
                          path={"/refresh-token"}
                          component={RefreshSession}
                        />
                        <Route
                          path="/forgotPassword"
                          component={ForgotPassword}
                        />
                        <Route
                          path="/reset_password"
                          component={ResetPassword}
                        />
                        {/* <Route path={`/rtl`} component={RtlLayout} /> */}
                        <Redirect from="/" to="/auth/default" />
                      </Switch>
                    </ErrorBoundary>
                  </Suspense>
                  <AuthVerify />
                </HashRouter>
                <ToastContainer
                  theme="light"
                  autoClose={2000}
                  position="top-right"
                />
              </ThemeEditorProvider>
            </I18nextProvider>
          </React.StrictMode>
        </ChakraProvider>
      </ErrorBoundary>
  </Provider>,
  document.getElementById("root")
);
