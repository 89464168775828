import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../../api/apiEndPoints';
import { getRequest } from 'screens/utils/axiosUtils';

const initialState = {
  property: {},
  loading: 'idle',
  error: null,
};

export const fetchProperty = createAsyncThunk(
  'properties/fetchProperty',
  async (propertyId, { rejectWithValue }) => {
    try {
      const propertyById = await getRequest(`${api().properties}/${propertyId}`);
      if (propertyById) return propertyById;
      return [];
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const propertySlice = createSlice({
  name: 'propertyState',
  initialState,
  reducers: {
    resetPropertyState: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProperty.pending, (state) => {
        state.loading = 'loading';
        state.error = null;
      })
      .addCase(fetchProperty.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.property = action.payload;
        state.error = null;
      })
      .addCase(fetchProperty.rejected, (state, action) => {
        state.loading = 'error';
        state.error = action.payload;
      });
  },
});

export const propertySelector = (state) => state.property.property;
export const loadingSelector = (state) => state.property.loading;
export const errorSelector = (state) => state.property.error;
export const { resetPropertyState } = propertySlice.actions;

// Export the reducer as default
export default propertySlice.reducer;
