import { configureStore } from "@reduxjs/toolkit";
import bookingsReducer from "../features/inbox/bookingsSlice";
import filtersReducer from "../features/filters/filtersSlice";
import filterElementsReducer from "../features/filters/filterElementsSlice";
import appliedFiltersReducer from "../features/filters/appliedFiltersSlice";
import bookingReducer from "../features/inbox/bookingSlice";
import bookingMessagesReducer from "../features/conversation/bookingMessagesSlice";
import userBookingsReducer from "../features/conversation/userBookingsSlice";
import questionsReducer from "../features/questions/questionsSlice";
import flowsReducer from "../features/flows/flowsSlice";
import actionsReducer from "../features/pendingActions/actionsSlice";
import authReducer from "../features/authentication/authSlice";
import templatesReducer from "../features/templates/templatesSlice";
import flowSearchReducer from "redux/features/tableSearch/flowsSearchSlice";
import propertySearchReducer from "redux/features/tableSearch/propertySearchSlice";
import questionSearchReducer from "redux/features/tableSearch/questionsSearchSlice";
import actionSearchReducer from "redux/features/tableSearch/actionsSearchSlice";
import chatSearchReducer from "redux/features/tableSearch/chatSearchSlice";
import contactsReducer from "redux/features/contacts/contactsSlice";
import bookingActionsReducer from "redux/features/conversation/bookingActionsSlice";
import flowActionsReducer from "redux/features/flows/flowActionsSlice";
import userMessagesReducer from "redux/features/otherConversation/userMessagesSlice";
import usersReducer from "redux/features/users/usersSlice";
import adminsReducer from "redux/features/users/adminsSlice";
import bulkMessageReducer from "redux/features/inbox/bulkMessageSlice";
import activeSubmenuReducer from "redux/features/sidebar/activeSubmenu";
import propertiesReducer from "redux/features/properties/propertiesSlice";
import propertyReducer from "redux/features/properties/propertySlice";
import checkboxReducer from "redux/features/inbox/checkboxSlice";

export const store = configureStore({
  reducer: {
    bookings: bookingsReducer,
    filters: filtersReducer,
    filterElements: filterElementsReducer,
    appliedFilters: appliedFiltersReducer,
    booking: bookingReducer,
    bookingMessages: bookingMessagesReducer,
    userBookings: userBookingsReducer,
    questions: questionsReducer,
    actions: actionsReducer,
    userAuth: authReducer,
    flows: flowsReducer,
    templates: templatesReducer,
    flowSearch: flowSearchReducer,
    questionSearch: questionSearchReducer,
    actionSearch: actionSearchReducer,
    chatSearch: chatSearchReducer,
    contacts: contactsReducer,
    bookingActions: bookingActionsReducer,
    flowActions: flowActionsReducer,
    userMessages: userMessagesReducer,
    users: usersReducer,
    admins: adminsReducer,
    bulkMessage: bulkMessageReducer,
    activeSubmenu: activeSubmenuReducer,
    properties: propertiesReducer,
    property: propertyReducer,
    propertySearch: propertySearchReducer,
    checkboxes: checkboxReducer,
  },
});