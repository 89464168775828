import React from "react";
import { Box, Text, Link, Flex } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
    localStorage.clear();
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flex textAlign="center" h="100vh" alignItems="center" justifyContent="center">
          <Box p={5} rounded="lg" color="secondaryGreen.200" my="auto">
            <Text fontSize="4xl" mb={10}>An error occurred!</Text>
            {/* Optionally display a more descriptive error message */}
            {/* {this.state.error.message && (
              <Text fontSize="md">{this.state.error.message}</Text>
            )} */}
            {/* Optionally provide a button to retry the action */}
            <Text fontSize="2xl">
              {" "}
              Please{" "}
              <Link
                onClick={() => this.setState({ hasError: false })}
                color="secondaryGreen.200"
                textDecoration="underline"
              >
                reload
              </Link>{" "}
              or go to{" "}
              <RouterLink
                to="/admin/inbox"
                onClick={() => this.setState({ hasError: false })}
                color="secondaryGreen.200"
              >
                <Text as="span" textDecoration="underline">
                  inbox
                </Text>
              </RouterLink>
              .
            </Text>
          </Box>
        </Flex>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
